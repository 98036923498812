<template>
  <iModal
    :visible="visible"
    :show-footer="false"
    :title="title"
    width="hug"
    @close="closeDetails"
  >
    <template #body>
      <iColumn class="modal-content-container">
        <iStyledCard
          v-for="(config, configIndex) in templateSummary"
          :key="configIndex"
          :max-width="350"
          width="fill"
          single-column-metrics
          :metrics="metrics(config.features)"
        >
          <template #title>
            <iSubHeading variant="subtle">
              {{ camelToTitleCase(config.title) }}
            </iSubHeading>
          </template>
        </iStyledCard>
      </iColumn>
    </template>
  </iModal>
</template>

<script>

import { camelToTitleCase } from "@bloglovin/vue-framework";

export default {
  name: "TemplateDetails",
  props: {
    title: {
      type: String,
      required: false,
      default: "",
    },
    visible: {
      type: Boolean,
      required: true,
    },
    template: {
      type: Object,
      required: true,
    },
  },
  emits: ["closeDetails"],
  computed: {
    templateSummary() {
      let signUpAndLogin = {
        privatePublication: this.template.isPrivatePublication,
        allowUsersToSignUpAndLogin: this.template.canSignUp,
        requireAccountApproval: this.template.requireAccountApproval,
      };
      let contentExclusivity = {
        someExclusiveContent: this.template.exclusiveContent == "someExclusive",
        exclusiveContentTeaserCards: this.template.exclusiveContentTeasers == "cardOnly",
        freeContentTeaserCards: this.template.freeContentTeasers == "cardOnly",
        exclusiveCommunityChat: this.template.exclusiveCommunityType == "chat",
        exclusiveCommunityForum: this.template.exclusiveCommunityType == "forum",
        freeCommunityChat: this.template.freeCommunityType == "chat",
        freeCommunityForum: this.template.freeCommunityType == "forum",
      };
      let fanEngagement = {
        LikePosts: this.template.canLikePosts,
        VoteOnPosts: this.template.canVoteOnPosts,
        CommentOnPosts: this.template.canCommentOnPosts,
        LikeComments: this.template.canLikeComments,
        ReplyToComments: this.template.canReplyToComments,
        VoteOnComments: this.template.canVoteOnComments,
      };
      let template = { signUpAndLogin, contentExclusivity, fanEngagement };
      const items = Object.keys(template).map(config => (
        {
          title: config,
          features: this.featureSummary(template[config]),
        }
      ));
      return items.filter(item => item.features.length > 0);
    },
  },
  methods: {
    camelToTitleCase,
    metrics(features) {
      return features.map(feature => {
        return {
          name: this.camelToTitleCase(feature.feature),
          icon: feature.value ? "check-circle" : "close",
          iconVariant: feature.value ? "branded" : "error",
        };
      });
    },
    closeDetails() {
      this.$emit("closeDetails");
    },
    featureSummary(features) {
      return Object.keys(features).map( feature => {
        return {
          feature,
          value: features[feature],
        };
      });
    },
  },
  styleGuide: () => ({
    metricsGap: { "size.spacing": "standard" },
  }),
};
</script>

<style scoped>
:deep(.modal-body) {
  height: 50vh;
  overflow-y: auto;
}
:deep(.card-metrics){
  row-gap: v-bind("$getStyles.metricsGap");
}
</style>
