<template>
  <div
    class="logo"
    @click="onClick"
    @touchstart.prevent="startPressAndHold"
    @touchend.prevent="cancelPressAndHold"
  >
    <img class="logo logo-image" :src="imageMobileSrc" alt="logo">
    <span v-if="showTitle" class="logo-text">inspiration<span class="logo-text-accent">.co</span></span>
  </div>
</template>

<script>
export default {
  name: "Logo",
  props: {
    height: {
      type: String,
      required: false,
      default: "60px",
    },
    width: {
      type: String,
      required: false,
      default: "unset",
    },
    showTitle: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      clickCount: 0,
      pressTimer: null,
    };
  },
  computed: {
    imageMobileSrc() {
      const images = [
        "/images/logo/windmill-gradient-v2.svg",
        "/images/logo/starfish-gradient.svg",
        "/images/logo/spiral-gradient.svg",
      ];
      return images.at(this.clickCount % 3);
    },
  },
  methods: {
    onClick(event) {
      if (event.altKey) {
        this.clickCount++;
      } else {
        window.location.href = "/";
      }
    },
    startPressAndHold() {
      this.pressTimer = setTimeout(() => {
        this.clickCount++;
      }, 2000);
    },
    cancelPressAndHold() {
      clearTimeout(this.pressTimer);
      this.pressTimer = null;
    },
  },
};
</script>

<style scoped lang="scss">

.logo {
  height: v-bind(height);
  cursor: pointer;
  display: flex;
  align-items: center;
}

.logo-image {
  width: 75px;
}

.logo-text {
  font-size: 30px;
  font-family: Alegreya Sans;
  margin-bottom: 8px;
}

.logo-text-accent {
  color: #0f8b00;
}
</style>
