import { defineStore } from "pinia";
import { usePublicationStore } from "~/stores/publication-store";
import { $axios } from "@bloglovin/vue-framework";
import { useGeneralStore } from "%/stores/general-store";
import { links } from "%/enums/link-enums";
export const useConfigurationStore = defineStore("configuration-store", {
  state: () => ({
    $_configuration: {}, // used as a private state variable
    isLoadingUpdateConfiguration: false,
    publicationStagingVersionId: usePublicationStore().publicationVersionId,
  }),
  getters: {
    publicationId() {
      return usePublicationStore().publicationId;
    },
    areSubscriptionsAvailable() {
      return this.canSignUp && this.availableSubscriptionPlans.length > 0;
    },
    canCreateQuickPost() {
      return this.ugcInterface == "quickPost";
    },
    isSignupAndLoginEnabled() {
      return this.canSignUp || this.isPrivatePublication;
    },
    primaryNavigation() {
      return this.$_configuration.primaryNav;
    },
    availableSubscriptionPlans() {
      return this.$_configuration.availableSubscriptionPlans;
    },
    canVoteOnPosts() {
      return this.$_configuration.canVoteOnPosts;
    },
    canLikePosts() {
      return this.$_configuration.canLikePosts;
    },
    commentModerationStrategy() {
      return this.$_configuration.commentModerationStrategy;
    },
    commentReplyDisplayStyle() {
      return this.$_configuration.commentReplyDisplayStyle;
    },
    digitalProductsVisible() {
      return this.$_configuration.digitalProductsVisible;
    },
    commentReplyNestingLimit() {
      return this.$_configuration.commentReplyNestingLimit;
    },
    canLikeComments() {
      return this.$_configuration.canLikeComments;
    },
    isPrivatePublication() {
      return this.$_configuration.isPrivatePublication;
    },
    requireAccountApproval() {
      return this.$_configuration.requireAccountApproval;
    },
    ugcInterface() {
      return this.$_configuration.ugcInterface;
    },
    freeCommunityType() {
      return this.$_configuration.freeCommunityType;
    },
    exclusiveCommunityType() {
      return this.$_configuration.exclusiveCommunityType;
    },
    commentDefaultSortingStrategy() {
      return this.$_configuration.commentDefaultSortingStrategy;
    },
    ugcModerationStrategy() {
      return this.$_configuration.ugcModerationStrategy;
    },
    canCommentOnPosts() {
      return this.$_configuration.canCommentOnPosts;
    },
    canReplyToComments() {
      return this.$_configuration.canReplyToComments;
    },
    canVoteOnComments() {
      return this.$_configuration.canVoteOnComments;
    },
    canSignUp() {
      return this.$_configuration.canSignUp;
    },
    dynamicComponents() {
      return this.$_configuration.dynamicComponents;
    },
  },
  actions: {
    updateConfiguration(newConfigData) {
      this.isLoadingUpdateConfiguration = true;
      const apiUri = useGeneralStore().getLink(links.api);
      $axios.post(apiUri + `/publication/${this.publicationId}/version/${this.publicationStagingVersionId}/update`, {
        config_data: newConfigData,
      }, {
        headers: {
          Authorization: "",
        },
      }).then(response => {
        this.$_configuration = response.data.configuration;
        this.publicationStagingVersionId = response.data.publicationVersionId;
      }).finally(() => {
        this.isLoadingUpdateConfiguration = false;
      });
    },
  },
});
