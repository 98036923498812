<template>
  <iModal
    v-if="show"
    :close-on-backdrop="false"
    :close-on-escape="false"
    :close-on-secondary="false"
    :show-footer="false"
    :max-width="600"
    width="fill"
    :content-height="80"
    scrollable
    title="Create a new variant"
    @close="$emit('close')"
  >
    <template #body>
      <iColumn>
        <iRow v-if="hasError" align="center">
          <iText variant="error">
            Error creating story variant, please contact support.
          </iText>
        </iRow>
        <iRow>
          <iSubHeading>{{ postTitle || 'No title' }}</iSubHeading>
        </iRow>
        <iRow align="center">
          <iStyledCard
            v-for="variant in STORY_VARIANT_TYPES"
            :key="variant.value"
            :description="variant.description"
            :splash-image="variant.splash"
            :title="variant.label"
            :max-width="270"
            width="fill"
            :disabled="isCreatingStoryVariant"
          >
            <template #footer>
              <iButton
                variant="secondary"
                width="fill"
                loading-spinner-position="inside"
                :disabled="isCreatingStoryVariant"
                :is-loading="creatingVariantType === variant.value && isCreatingStoryVariant"
                @click="createVariant(variant.value)"
              >
                Create
              </iButton>
            </template>
          </iStyledCard>
        </iRow>
      </iColumn>
    </template>
  </iModal>
</template>

<script>
import { STORY_VARIANT_TYPES } from "@/constants/story-variant-type-constants";
import { mapActions, mapState } from "pinia";
import { usePublicationStore } from "@/stores/publication-store";

export default {
  name: "CreateVariantsModal",
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    postTitle: {
      type: String,
      required: false,
      default: "No title",
    },
    postId: {
      type: Number,
      required: true,
    },
  },
  emits: ["close"],
  data() {
    return {
      STORY_VARIANT_TYPES,
      creatingVariantType: null,
      hasError: false,
    };
  },
  computed: {
    ...mapState(usePublicationStore, ["isCreatingStoryVariant"]),
  },
  methods: {
    ...mapActions(usePublicationStore, ["createStoryVariant"]),
    createVariant(variantType) {
      this.creatingVariantType = variantType;
      this.createStoryVariant(this.postId, variantType, data => {
        this.creatingVariantType = null;
        this.hasError = false;
        document.location.href = `/stories/editor?post_id=${data.postId}`;
      }, () => {
        this.creatingVariantType = null;
        this.hasError = true;
      });
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
