import { defineStore } from "pinia";
import { DEFAULT_ORG_LOGO } from "%/constants/publication-constants";
import { $axios } from "@bloglovin/vue-framework";
import { links } from "%/enums/link-enums";
import { useGeneralStore } from "%/stores/general-store";
import { useConfigurationStore } from "%/stores/configuration-store";
import { useLovedStoryFeedStore } from "SmartFeed/stores/feed/loved-story-feed-store";
import { usePostFeedStore } from "SmartFeed/stores/feed/post-feed-store";
import SmartFeedEngine from "SmartFeed/smart-feed-engine";
import dayjs from "dayjs";

const DEFAULT_LAYOUT = "Blog";
export const useFanPublicationStore = defineStore("publication-store-fan", {
  state: () => ({
    layoutName: "community",
    loading: false,
    posts: [],
    post: null,
    postViewType: "",
    publicationId: 0,
    publicationData: {},
    baseUri: "",
    stagingUri: "",
    productionUri: "",
    userDetailsChangeError: null,
    isLoading: false,
    loadingPosts: false,
    isVotingActionLoading: false,
    isLikeActionLoading: false,
    emailStatus: {
      requestedNewEmail: "",
      isEmailConfirmed: true,
      isEmailBeingChanged: false,
    },
    loginErrorMessage: "",
    signupErrorMessage: "",
    uploadingPostImage: false,
    updatePostIsLoading: false,
    canConfigurePublication: false,
    deployments: [],
    publicationVersions: [],
    lastDeploymentId: 0,
  }),
  getters: {
    publicationName() {
      return this.publicationData.name;
    },
    appId() {
      return useGeneralStore().appId;
    },
    orgId() {
      return useGeneralStore().orgId;
    },
    isSubscribed() {
      return window.blclient.data.pageProps.subscribed;
    },
    usersApiV1() {
      return useGeneralStore().getLink("usersV1Api");
    },
    contentApiV1() {
      return useGeneralStore().getLink(links.contentApiV1);
    },
    creatorApiV1() {
      return useGeneralStore().getLink(links.api);
    },
    fanApiV1() {
      return useGeneralStore().getLink(links.fanApiV1);
    },
    fanUrl() {
      return useGeneralStore().fanUrl;
    },
    userData() {
      return useGeneralStore().userData;
    },
    userId() {
      return useGeneralStore().userId;
    },
    publicationLogo() {
      if (!this.publicationData.publication_logo) {
        return DEFAULT_ORG_LOGO;
      }
      return this.publicationData.publication_logo;
    },
    isUserLoggedIn() {
      return useConfigurationStore().isSignupAndLoginEnabled && this.userId > 0;
    },
    deploymentHistory: state => {
      const groupedVersions = [];
      let subGroupedVersions = [];
      state.publicationVersions.forEach((version, index) => {
        const by = version.username || "Unknown";
        const date = dayjs.unix(version.date_created).format("h:mma MMMM D, YYYY");
        const description = `by ${by} at ${date}`;
        const memo = version.memo || "Unknown changes";
        const isCurrentProduction = version.production_version_id === version.publication_version_id;
        let title = isCurrentProduction ? "Deployment" : `${memo}`;

        let workingVersion = version.version.split(".")[0];

        const item = {
          title,
          description,
          bubbleSmall: !isCurrentProduction,
          primary: isCurrentProduction,
          secondary: index === 0 && !isCurrentProduction,
          bubbleText: "",
          version: workingVersion,
          firstIndex: index === 0,
        };

        const lastItemInGroup = subGroupedVersions[0];
        if (!lastItemInGroup) {
          subGroupedVersions.push(item);
        } else {
          if (lastItemInGroup.version === workingVersion && index < state.publicationVersions.length - 1) {
            subGroupedVersions.push(item);
          } else {
            if (index === state.publicationVersions.length - 1) {
              subGroupedVersions.push(item);
            }
            if (!lastItemInGroup.firstIndex) {
              lastItemInGroup.title = "Deployment";
              lastItemInGroup.bubbleSmall = false;
            }

            groupedVersions.push({
              ...lastItemInGroup,
            });
            subGroupedVersions.splice(0, 1);
            if (subGroupedVersions.length > 3) {
              groupedVersions.push({
                title: "Multiple Changes",
                description: "",
                bubbleText: "",
                children: subGroupedVersions,
              });
            } else {
              groupedVersions.push(...subGroupedVersions);
            }

            subGroupedVersions = [item];
          }
        }
      });

      return groupedVersions;
    },
  },
  actions: {
    setCanConfigurePublication(canConfigurePublication) {
      this.canConfigurePublication = canConfigurePublication;
    },
    updateTheme(theme, themeId, successHandler, errorHandler) {
      $axios.post(useGeneralStore().links.api + `/themes/org/${this.publicationData.org_id}/${themeId}/deploy/staging`, {
        theme: { ...theme },
        publication_id: this.publicationId,
        publication_version_id: useConfigurationStore().publicationStagingVersionId,
      }, {
        headers: {
          Authorization: "",
        },
      }).then(response => {
        useConfigurationStore().publicationStagingVersionId = response.data.publicationVersionId;
        successHandler();
      }).catch(errorHandler);
    },
    setBaseUri(baseUri, stagingUri, productionUri) {
      this.baseUri = baseUri;
      this.stagingUri = stagingUri;
      this.productionUri = productionUri;
    },
    setPublicationData(data) {
      this.publicationId = data.publicationId;
      this.publicationData = data.publication;
      this.layoutName = DEFAULT_LAYOUT;
    },
    setPublicationConfiguration(configuration) {
      this.publicationConfiguration = configuration;
    },
    async likePost(postId) {
      this.isLikeActionLoading = true;
      await this.apiPost(
        `/publication/post/${postId}/like`,
        {},
        response => {
          this.isLikeActionLoading = false;
          new SmartFeedEngine().addEntities(response.entities);
          useLovedStoryFeedStore().reload();
          this.post.like_status = response.entities.communityPost[postId].likeStatus;
          this.post.num_likes = response.entities.communityPost[postId].numLikes;
        },
        () => {},
        () => {
          this.isLikeActionLoading = false;
        },
      );
    },
    getPageOfDeploymentHistory(reload) {
      // /publication/{publication_id}/deployment-history
      this.apiGet(`${this.creatorApiV1}/publication/${this.publicationId}/deployment-history`, {
        last_id: reload ? 0 : this.lastDeploymentId,
      }, response => {
        this.deployments = response.deployments;
        this.lastDeploymentId = response.lastId || 0;
        this.publicationVersions = response.publicationVersions;
      }, () => {});
    },
    async unlikePost(postId) {
      this.isLikeActionLoading = true;
      await this.apiPost(
        `/publication/post/${postId}/unlike`,
        {},
        response => {
          this.isLikeActionLoading = false;
          new SmartFeedEngine().addEntities(response.entities);
          useLovedStoryFeedStore().reload();
          this.post.like_status = response.entities.communityPost[postId].likeStatus;
          this.post.num_likes = response.entities.communityPost[postId].numLikes;
        },
        () => {
          this.isLikeActionLoading = false;
        },
      );
    },
    async upVotePost(postId) {
      this.isVotingActionLoading = true;
      await this.apiPost(
        `/publication/post/${postId}/upvote`,
        {},
        response => {
          this.isVotingActionLoading = false;
          new SmartFeedEngine().addEntities(response.entities);
          this.post.vote_status = response.entities.communityPost[postId].voteStatus;
          this.post.vote_score = response.entities.communityPost[postId].voteScore;
        },
        () => {},
        () => {
          this.isVotingActionLoading = false;
        },
      );
    },
    async downVotePost(postId) {
      this.isVotingActionLoading = true;
      await this.apiPost(
        `/publication/post/${postId}/downvote`,
        {},
        response => {
          this.isVotingActionLoading = false;
          new SmartFeedEngine().addEntities(response.entities);
          this.post.vote_status = response.entities.communityPost[postId].voteStatus;
          this.post.vote_score = response.entities.communityPost[postId].voteScore;
        },
        () => {},
        () => {
          this.isVotingActionLoading = false;
        },
      );
    },
    removeVotePost(postId) {
      this.isVotingActionLoading = true;
      return this.apiPost(
        `/publication/post/${postId}/remove-vote`,
        {},
        response => {
          this.isVotingActionLoading = false;
          new SmartFeedEngine().addEntities(response.entities);
          this.post.vote_status = response.entities.communityPost[postId].voteStatus;
          this.post.vote_score = response.entities.communityPost[postId].voteScore;
        },
        () => {},
        () => {
          this.isVotingActionLoading = false;
        },
      );
    },
    refreshPosts() {
      this.loadingPosts = true;
      this.apiGet(this.fanApiV1 + "/posts", { publication_id: this.publicationId }, posts => {
        this.posts = posts;
        this.loadingPosts = false;
      }, () => {
        this.loadingPosts = false;
      });
    },
    saveSessionDataAndRedirect(postData) {
      sessionStorage.setItem("postCreationData", JSON.stringify(postData));
      const encodedCurrentUri = encodeURIComponent(window.location.pathname);
      window.location.href = `${this.fanUrl}/login?redirect=${encodedCurrentUri}`;
      return;
    },
    uploadPostImage(selectedFile, postData) {
      if (!this.isUserLoggedIn) {
        this.saveSessionDataAndRedirect(postData);
      }
      this.uploadingPostImage = true;

      var formData = new FormData();
      formData.append("org_id", this.orgId);
      formData.append("publication_id", this.publicationId);
      formData.append("image", selectedFile);

      this.apiPostForm(`${this.contentApiV1}/post/image/upload`,
        formData
        , () => {
        },
        () => {},
        () => {
          this.uploadingPostImage = false;
        });
    },
    createPublicationPost(title, html, hashtags, callback) {
      if (!this.isUserLoggedIn) {
        this.saveSessionDataAndRedirect({
          title: title,
          html: html,
          hashtags: hashtags,
        });
      }
      this.updatePostIsLoading = true;
      const data = {
        publication_id: this.publicationId,
        org_id: this.orgId,
        post_title: title,
        post_content: html,
        post_hashtags: hashtags,
      };
      this.apiPost(`${this.creatorApiV1}/publication/${this.publicationId}/quick-post`, {
        data,
      }, () => {
        usePostFeedStore().reload();
        this.updatePostIsLoading = false;
      }, () => {
        this.updatePostIsLoading = false;
        callback(false);
      });
    },
  },
});

export default useFanPublicationStore;
