<template>
  <iRow v-if="userLayoutChoices.length > 0" align="right">
    <iSelect v-model="layout" :items="iSelectLayoutChoices" return-value />
  </iRow>
  <iInfiniteScroll
    :has-more="smartFeedStore.hasMore"
    :is-loading="smartFeedStore.isLoading"
    height="fill"
    :scroll-threshold="500"
    @load-more="smartFeedStore.getSmartFeed()"
  >
    <slot name="above-content" />
    <template v-if="smartFeedStore.feed.length > 0">
      <slot />
      <component
        :is="wrapperComponent"
        v-if="smartFeedStore.feed.length > 0"
        :vertical-align="verticalAlign"
        :sticky-headers="stickyHeaders"
        v-bind="wrapperComponentProps"
      >
        <SmartFeedItem
          v-for="feedItem in smartFeedStore.feed"
          :key="`${feedItem.type}-${feedItem.id}`"
          :entity-type="feedItem.type"
          :entity-id="feedItem.id"
          :layout="entityLayout"
          :fields="smartFeedStore.tableFields"
          :show-unknown-entities-as-placeholder="showUnknownEntitiesAsPlaceholder"
        />
      </component>
    </template>
    <slot v-else name="empty-state" />
  </iInfiniteScroll>
</template>

<script>
import SmartFeedItem from "SmartFeed/components/SmartFeedItem.vue";
import SmartFeedTable from "SmartFeed/components/SmartFeedTable.vue";
import { Enum } from "@bloglovin/vue-component-library";
import BaseSmartFeedEngine from "SmartFeed/smart-feed-engine";

export default {
  name: "SmartFeed",
  components: { SmartFeedTable, SmartFeedItem },
  props: {
    piniaStore: {
      type: Function,
      required: true,
    },
    userLayoutChoices: {
      type: Array,
      required: false,
      default: () => [],
    },
    defaultEntityLayout: {
      type: Enum,
      required: true,
      options: [...BaseSmartFeedEngine.VALID_ENTITY_LAYOUTS],
      // @todo add a validator to ensure that default smart feed layout table has default entity layout table and continues to validate.
    },
    defaultSmartFeedLayout: {
      type: Enum,
      options: [...BaseSmartFeedEngine.VALID_SMART_FEED_LAYOUTS],
      required: true,
    },
    // this is currently non-functional after refactor - @TODO - fix
    showUnknownEntitiesAsPlaceholder: {
      type: Boolean,
      required: false,
      default: true,
    },
    stickyHeaders: {
      type: Boolean,
      required: false,
      default: false,
    },
    verticalAlign: {
      type: Enum,
      required: false,
      options: ["top", "middle"],
      default: "top",
    },
  },
  data() {
    return {
      entityLayout: null,
      smartFeedLayout: null,
      smartFeedStore: null,
    };
  },
  computed: {
    iSelectLayoutChoices() {
      return this.userLayoutChoices.map(userLayoutChoice => {
        return {
          id: userLayoutChoice,
          name: userLayoutChoice,
        };
      });
    },
    wrapperComponent() {
      if (this.smartFeedLayout === BaseSmartFeedEngine.SMART_FEED_LAYOUT_TABLE) {
        return SmartFeedTable;
      }
      if (this.smartFeedLayout === BaseSmartFeedEngine.SMART_FEED_LAYOUT_GRID) {
        return "iMasonry";
      }
      return "iRow";
    },
    wrapperComponentProps() {
      if (this.smartFeedLayout === BaseSmartFeedEngine.SMART_FEED_LAYOUT_TABLE) {
        return { fields: this.smartFeedStore.tableFields };
      }
      if (this.smartFeedLayout === BaseSmartFeedEngine.SMART_FEED_LAYOUT_GRID) {
        return { align: "left" };
      }
      return {};
    },
  },
  created() {
    this.smartFeedStore = this.piniaStore(this.$pinia);
    if (this.smartFeedStore.feed.length === 0) {
      this.smartFeedStore.getSmartFeed();
    }
    this.entityLayout = this.defaultEntityLayout;
    this.smartFeedLayout = this.defaultSmartFeedLayout;
  },
};
</script>
